import React from 'react';
import {useState,useEffect} from 'react';
import "../style/sbancatotore.css";

const Sbancatotore = () => {
    const [bolletta,setBolletta]=useState("");

    const prova = {lista_partite: [{"giornata":5,"casa":"Atalanta","fuori":"Sassuolo","risultato":"1x + Gol"},{"giornata":5,"casa":"Fiorentina","fuori":"Inter","risultato":"2"},{"giornata":5,"casa":"Salernitana","fuori":"Hellas Verona","risultato":"x"},{"giornata":5,"casa":"Spezia","fuori":"Juventus","risultato":"2 + Gol"},{"giornata":5,"casa":"Cagliari","fuori":"Empoli","risultato":"Gol + Over 2.5"},{"giornata":5,"casa":"Milan","fuori":"Venezia","risultato":"1 HH (0:2)"},{"giornata":5,"casa":"Sampdoria","fuori":"Napoli","risultato":"2 + Multigol 3-5"},{"giornata":5,"casa":"Torino","fuori":"Lazio","risultato":"2"},{"giornata":5,"casa":"Roma","fuori":"Udinese","risultato":"1x + Over 2.5"}], max: 5};

    useEffect(()=>{
        getBolletta(0).
        then(res => setBolletta(res));
    },[]);

    useEffect(()=>{
        //console.log(bolletta);  
    },[bolletta]); 

    const getBolletta = async (giornata) => {
        return await fetch("/php/sbancatotore.php?giornata=" + giornata)
        .then(res => res.json());
    }

    const cambiaBolletta = () => {
        var select = document.getElementById("giornata");
        if(select !== null && select !== undefined){
            getBolletta(select.options[select.selectedIndex].innerText).
            then(res => setBolletta(res));
        }
    }

    const creaTendina = () => {
        let tendina = "<select>";
        for(var i = 3; i<bolletta.max ; i++){
            tendina += "<option value='" + i +"'>" + i + "</option>";
        }
        tendina += "<option value=" + bolletta.max + " selected>" + bolletta.max + "</option>";
        return tendina;
    }

    if(bolletta !== "" && bolletta.errore === undefined && bolletta.lista_partite !== undefined){
        return(
            <div className="sbanca-container">
                <div class="titolo-pagina">SBANCATOTORE</div>
                <div className="sbanca-titolo">
                <select name="giornata" id="giornata" dangerouslySetInnerHTML={{ __html: creaTendina() }} onChange={() => cambiaBolletta()}>
                </select>
                     di Serie A
                </div>
                <div className="sbanca-partite">
                    <table>
                        <thead>
                            <tr>
                                <th>Partita</th>
                                <th>Risultato</th>
                            </tr>
                        </thead>
                        <tbody>
                        {bolletta.lista_partite.map((partita)=>{
                    return (
                        <tr>
                            <td>
                                <div className="sbanca-squadre">
                                    {partita.casa} - {partita.fuori}
                                </div>
                            </td>
                            <td>
                                <div className="sbanca-risultato">
                                    {partita.risultato}
                                </div>
                            </td>
                        </tr>
                    );
                    })}
                    </tbody>
                    </table>
                </div>
            </div>
        );
    }
    else{
        return(
            <div className="sbanca-container">
                <div class="titolo-pagina">SBANCATOTORE</div>
                <div class="caricamento">Caricamento...</div>
            </div>
        );
    }
}

export default Sbancatotore;