import React from 'react';
import {useState,useEffect} from 'react';
import "../style/classifica.css";

const Classifica = () => {
    const [classifica,setClassifica]=useState([]);
    const anno = 2021;

    useEffect(()=>{
        fetch("/php/classifica.php?anno=" + anno)
        .then(res => res.json())
        .then(json => setClassifica(json));    
        },[]);

    if(classifica === []){
        return(
            <div class="contenitore">
                <div className="classifica-container">
                    Caricamento...
                </div>
            </div>
        );
    }

    return(
        <div className="classifica-container">
            <div class="titolo-pagina">La Classifica</div>
            <table>
                <tr>
                    <th>#</th>
                    <th>Squadra</th>
                    <th>Punti</th>
                    <th>Punti totali</th>
                </tr>
                {classifica.map((riga)=>{
                return (
                    <tr>
                        <td>{riga.posizione}</td>
                        <td>{riga.squadra}</td>
                        <td>{riga.punti}</td>
                        <td>{riga.totali}</td>
                    </tr>
                    );
                })}
            </table>
        </div>
    );
}

export default Classifica;