import React from 'react';
import {useState,useEffect} from 'react';
import "../style/calendario.css";

const Calendario = () => {
    
    const [ultimaPartita, setUltimaPartita] = useState(0);
    const [partiteList,setPartiteList] = useState("");

    useEffect(()=>{
        fetch("/php/calendario.php?anno=2021")
        .then(res => res.json())
        .then(json =>{
            setPartiteList(json);
            return json;
        })
        .then(json => {
            let i;
            for(i = 1; i<json.giornate.length; i++){
                if(json.giornate[i].partite[0].gc === -1){
                    setUltimaPartita(i-1);
                    return;
                }
            }
            setUltimaPartita(i-1);
        });
    },[]);

    useEffect(()=>{
      console.log(partiteList);
    },[partiteList]);

    if(partiteList === ""){
      return(
        <div class="contenitore">
            <div className="calendario-container">
                Caricamento...
            </div>
        </div>
    );
    }
    
    if(partiteList !== "" && partiteList !== undefined){
        let titolo;
        let prossima;
        if(ultimaPartita !== 0 && ultimaPartita !== partiteList.giornate.length-1){
            let giornata = partiteList.giornate[ultimaPartita + 1].giornata;
            titolo = <div className="titolo-pagina">Prossima Giornata</div>;
            prossima =
                            
                            <div className="giornata-container">
                                <div className="titolo-giornata">{giornata}° Giornata</div>
                                <div style={{borderTop: "1px solid black"}}></div>
                                {partiteList.giornate[ultimaPartita + 1].partite.map(partita => {
                                let divs = <div className="risultato-container">
                                                <div className="goal-container">
                                                    vs
                                                </div>
                                            </div>;
                                return(
                                    <div className="partita-container">
                                        <div className="casa-container">
                                            {partita.casa}
                                        </div>
                                        {divs}
                                        <div className="fuori-container">
                                            {partita.fuori}
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                            ;
        }
        else{
            titolo = "";
            prossima = "";
        }
      return(
          <div className="contenitore">
              <div className="titolo-pagina">Ultima Giornata</div>
              <div className="calendario-container">
                <div className="giornata-container">
                    <div className="titolo-giornata">{partiteList.giornate[ultimaPartita].giornata}° Giornata</div>
                    <div style={{borderTop: "1px solid black"}}></div>
                    {partiteList.giornate[ultimaPartita].partite.map(partita => {
                          let divs = <div className="risultato-container">
                                          <div className="goal-container">
                                              {partita.gc}&nbsp;-&nbsp;
                                              {partita.gf}
                                          </div>
                                          <div className="punti-container">
                                              {partita.pc}&nbsp;-&nbsp;
                                              {partita.pf}
                                          </div>
                                      </div>;
                          return(
                          <div className="partita-container">
                              <div className="casa-container">
                                  {partita.casa}
                              </div>
                              {divs}
                              <div className="fuori-container">
                                  {partita.fuori}
                              </div>
                          </div>
                          )
                      })}
                </div>
              {titolo}
              {prossima}
              <div className="titolo-pagina">Calendario Completo</div>
              {partiteList.giornate.map(giornata => {
                  return(
                  <div className="giornata-container">
                      <div className="titolo-giornata">{giornata.giornata}° Giornata</div>
                      <div style={{borderTop: "1px solid black"}}></div>
                      {giornata.partite.map(partita => {
                          let divs;
                          if(partita.gc !== -1){
                              divs = <div className="risultato-container">
                                          <div className="goal-container">
                                              {partita.gc}&nbsp;-&nbsp;
                                              {partita.gf}
                                          </div>
                                          <div className="punti-container">
                                              {partita.pc}&nbsp;-&nbsp;
                                              {partita.pf}
                                          </div>
                                      </div>;
                          }
                          else{
                              divs = <div className="risultato-container">
                                          <div className="goal-container">
                                              vs
                                          </div>
                                     </div>;
                          }

                          return(
                          <div className="partita-container">
                              <div className="casa-container">
                                  {partita.casa}
                              </div>
                              {divs}
                              <div className="fuori-container">
                                  {partita.fuori}
                              </div>
                          </div>
                          )
                          
                      })}
                  </div>
              )})}
              </div>
          </div>
      );
    }

}

export default Calendario;
