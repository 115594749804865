import React from 'react';
import {useState,useEffect} from 'react';
import $ from 'jquery';

const Aggiorna = () => {

    const anno = 2021;

    useEffect(()=>{
        classifica();
        calendario();
    },[]); 

    const classifica = async () => {
        await fetch("https://floating-shelf-20999.herokuapp.com/?url=https://leghe.fantacalcio.it/fanta-vela-stracciata",
            {
            mode: 'cors'
            })
        .then(html => html.text())
        .then(text => {
            let parser = new DOMParser();
            var doc = parser.parseFromString(text, "text/html");
            console.log(doc);
            return doc;
        })
        .then(doc =>{
            let righe = doc.getElementsByClassName("ranking-row");
            let classifica = [];
            for(let i = 0; i<righe.length; i++){
                let posizione = doc.getElementsByClassName("ranking-row")[i].children[0].children[0].innerHTML;
                let squadra = doc.getElementsByClassName("ranking-row")[i].children[2].children[0].children[0].innerHTML;
                let giocate = doc.getElementsByClassName("ranking-row")[i].children[3].children[0].innerHTML;
                let vinte = doc.getElementsByClassName("ranking-row")[i].children[4].children[0].innerHTML;
                let pareggiate = doc.getElementsByClassName("ranking-row")[i].children[5].children[0].innerHTML;
                let perse = doc.getElementsByClassName("ranking-row")[i].children[6].children[0].innerHTML;
                let fatti = doc.getElementsByClassName("ranking-row")[i].children[7].children[0].innerHTML;
                let subiti = doc.getElementsByClassName("ranking-row")[i].children[8].children[0].innerHTML;
                let differenza = doc.getElementsByClassName("ranking-row")[i].children[9].children[0].innerHTML;
                let punti = doc.getElementsByClassName("ranking-row")[i].children[10].children[0].innerHTML;
                let totali = doc.getElementsByClassName("ranking-row")[i].children[11].children[0].innerHTML;
                let element = {"posizione" : posizione,
                                "squadra" : squadra,
                                "giocate" : giocate,
                                "vinte" : vinte,
                                "pareggiate" : pareggiate,
                                "perse" : perse,
                                "fatti": fatti,
                                "subiti" : subiti,
                                "differenza" : differenza,
                                "punti" : punti,
                                "totali" : totali};
                classifica.push(element);
            }

            console.log(classifica);
            let richiesta = {"anno" : anno, "classifica" : classifica};
            return richiesta;
        })
        .then(async richiesta => {
            console.log(richiesta);
            await fetch("/php/aggiornaclassifica.php",
            {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(richiesta)
            })
            .then(text => console.log(text.data))
            .catch(err => console.log("stamm a problem"));
        });
    }

    const calendario = async () => {
        await fetch("https://floating-shelf-20999.herokuapp.com/?url=https://leghe.fantacalcio.it/fanta-vela-stracciata/calendario",
            {
            mode: 'cors'
            })
        .then(html => html.text())
        .then(text => {
            let parser = new DOMParser();
            var doc = parser.parseFromString(text, "text/html");
            console.log(doc);
            return doc;
        })
        .then(doc =>{
            let giornate = doc.getElementsByClassName("group wrapped")[0].childNodes;
            //let stampa = "";
            let creaGiornate = {"anno": anno,
                                "giornate": []};
            for(let i = 1; i<giornate.length; i+=2){
                let giornata = giornate[i].childNodes[1].childNodes[3].childNodes;
                //console.log(giornata.length);
                let indexGiornata = ((i-1)/2)+1;
                let creaGiornata = {"giornata": indexGiornata,
                                    "partite": []};
                for(let j = 1; j<giornata.length; j+=2){
                    let casa = giornata[j].childNodes[1].childNodes[5].childNodes[0].innerHTML;
                    let fuori = giornata[j].childNodes[3].childNodes[5].childNodes[0].innerHTML;
                    let gc = giornata[j].childNodes[1].childNodes[7].childNodes[0].nodeValue;
                    let gf = giornata[j].childNodes[3].childNodes[7].childNodes[0].nodeValue;
                    let pc = giornata[j].childNodes[1].childNodes[9].childNodes[0].nodeValue;
                    let pf = giornata[j].childNodes[3].childNodes[9].childNodes[0].nodeValue;
                    if(pc === "0.0" && pf === "0.0"){
                        gc = -1;
                        gf = -1;
                    }
                    let partita = {"casa": casa,
                                    "fuori": fuori,
                                    "gc": gc,
                                    "gf": gf,
                                    "pc": pc,
                                    "pf": pf};
                    creaGiornata.partite.push(partita);
                    //stampa += "("+indexGiornata+","+anno+",\'"+casa+"\',\'"+fuori+"\',"+gc+","+gf+",\'"+pc+"\',\'"+pf+"\'),";
                }
                creaGiornate.giornate.push(creaGiornata);
            }
            return creaGiornate;
        })
        .then(async json => {
            console.log(json);
            await fetch("/php/aggiornacalendario.php",
            {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            })
            .then(text => console.log(text.data))
            .catch(err => console.log("stamm a problem"));
        });
    }

    
    return(
        <div id="inner" >
            Aspetta...
        </div>
    );
}

export default Aggiorna;